<template>
	<div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Brand Management</p>
                <p class="text-title mb-0">You do not have permission to view all brands</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" v-if="clients.length > 0">
                                <v-autocomplete dense clearable hide-details label="Client" :items="clients" v-model="filters.client"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field dense clearable hide-details label="Brand Name" v-model="filters.name"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" v-if="clients.length > 0">
                                <v-autocomplete dense clearable hide-details label="Status" :items="statuses" v-model="filters.status"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_add">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="elevation-0" :ripple="false" @click='openCreateDialog'>
                        <v-icon small left>mdi-plus</v-icon> Create Brand
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.client.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <span class="mx-1 d-block" :key="index" v-for="(manager, index) in item.managers" v-text="manager.text"></span>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.status.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.created_on"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y v-if="hasMenu(item)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item :to="getViewUrl(item.id)" v-if="item.can_view">
                                                <v-list-item-title>View Brand</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row v-if="!loading && pagination.total_pages > 1">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
                </v-col>
            </v-row>
        </template>
		<create-dialog ref="createDialog" :clients="clients" :managers="managers" @created="updateItems"></create-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { parseUrlParams } from '@/utils/route'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import ProjectCreateDialog from './ProjectCreateDialog.vue'

export default {
	name: 'ProjectList',
	components: {
        messageNotifier: MessageNotifier,
		createDialog: ProjectCreateDialog,
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
			filters: {
				client: '',
				name: '',
				status: this.getStatus(),
				page: 1
			},
            permissions: {
                can_list: false,
                can_add: false
            },
			statuses: [],
			clients: [],
			managers: [],
			items: [],
			headers: [],
			pagination: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/project/getProjects', this.filters).then((response) => {
				this.statuses = response.data.statuses
				this.clients = response.data.clients
				this.managers = response.data.managers
				this.items = response.data.items
				this.headers = response.data.headers
				this.pagination = response.data.pagination
				this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { client: '', name: '', page: 1}
		},
		openCreateDialog: function() {
			this.createDialog.open()
		},
		updateItems: function(item) {
            this.reset()
            this.get()
            this.openNotifier(item.message)
		},
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        getViewUrl: function(id) {
            return 'brand/' + id + '/info'
		},
		getStatus: function() {
            var status = parseUrlParams(window.location.href).get('status')
            return status?status.toUpperCase():''
		},
        hasMenu: function(item) {
            return item.can_view
        }
	}
}

</script>


<style scoped>
.v-sheet.v-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
</style>