<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5">Create Brand</span>
				</v-card-title>
				<v-card-text>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="clients.length > 0">
                            <v-autocomplete dense clearable label="Client*" :rules="rules.client" :error-messages="errors.client" :items="clients" v-model="object.client"></v-autocomplete>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Brand Name*" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="managers.length > 0">
                            <v-autocomplete multiple clearable chips label="Project Manager" :error-messages="errors.managers" :items="managers" v-model="object.managers"></v-autocomplete>
                        </v-col>
					</v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { stringifyJSON } from '@/utils/encodes'

export default {
	name: 'ProjectCreateDialog',
	props: {
        clients: {
            type: Array,
            required: false,
            default: () => []
        },
        managers: {
            type: Array,
            required: false,
            default: () => []
        }
	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                client: '',
                name: '',
                managers: []
			},
			rules: {
                client: [
                    (value) => !!value || 'Client is required'
                ],
                name: [
                    (value) => !!value || 'Brand name is required',
                    (value) => value && value.length >= 2 && value.length <= 100 || 'Brand name must be between 6 and 100 characters'
                ]
			},
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.object = { client: '', name: '', managers: [] }
			this.errors = {}
			this.form.resetValidation()
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object)
                object.managers = stringifyJSON(object.managers)

				this.$store.dispatch('epanel/project/createProject', object).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		}
	}
}

</script>